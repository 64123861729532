/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/components/layout.css";
import React from 'react';
import { ColorModeScript } from '@chakra-ui/react';
import theme from './src/theme.js'; // or wherever your theme file is located

export const onRenderBody = ({ setPreBodyComponents }) => {
  setPreBodyComponents([
    <ColorModeScript initialColorMode={theme.config.initialColorMode} key="chakra-ui-no-flash" />,
  ]);
};