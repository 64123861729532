// src/@chakra-ui/gatsby-plugin/theme.js or src/gatsby-plugin-chakra-ui/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  config: {
    useSystemColorMode: false, // Disables automatic system color mode detection
    initialColorMode: 'dark', // Set the initial color mode to dark
  },
});

export default theme;
